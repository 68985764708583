import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "./channels"
import bootstrap from "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap"
import "@nathanvda/cocoon"
import "chartkick/chart.js"
// import 'tagify.js';
import "./chessboard.js"
import "./chess.js"
import './tag_input.js';
import './toggle_description.js';


//
Rails.start()
// Turbolinks.start()
ActiveStorage.start()

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))