// import Tagify from '@yaireo/tagify';

document.addEventListener('DOMContentLoaded', () => {
  const tagInput = document.querySelector('input[data-behavior="tagify"]');
  if (tagInput) {
    new Tagify(tagInput, {
      whitelist: tagInput.dataset.availableTags.split(','),
      dropdown: {
        enabled: 0,
      }
    });
  }
});
