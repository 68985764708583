if(document.getElementById("myBoard")) {
  $(function() {
    // Variables
    let $status = $('#status')
    let $fen = $('#fen')
    let $pgn = $('#pgn')
    let status = ''
    let future = [];
    let tmp_future = [];
    let move_counter = 0;

    // Initiate chessboard
    let game = new Chess()
    let config = {
      draggable: true,
      position: 'start',
      onDragStart: onDragStart,
      onDrop: onDrop,
      onSnapEnd: onSnapEnd
    }
    let board = Chessboard('myBoard', config)
    // board.pieceTheme = (piece) => {
    //   // wikipedia theme for white pieces
    //   // if (piece.search(/w/) !== -1) {
    //   //   return `/img/chesspieces/wikipedia/${piece}.png`;
    //   // }
    //
    //   // alpha theme for black pieces
    //   return `/img/chesspieces/wikipedia/${piece}.png`;
    // };

    updateOthers()

    $(document).on('click', '.movetofen', function() {
      // pgn = $(this).parent().parent().children(".movepgn").text()
      movetofen($(this).text(), $(this).parent().parent().children(".movecounter").text())
    });

    function onDragStart(source, piece, position, orientation) {
      // do not pick up pieces if the game is over
      if (game.game_over()) return false

      // only pick up pieces for the side to move
      if ((game.turn() === 'w' && piece.search(/^b/) !== -1) ||
        (game.turn() === 'b' && piece.search(/^w/) !== -1)) {
        return false
      }
    }

    function onSnapEnd() {
      board.position(game.fen())
    }

    function updateStatus() {
      let moveColor = 'White'
      if (game.turn() === 'b') {
        moveColor = 'Black'
      }

      // checkmate?
      if (game.in_checkmate()) {
        status = 'Game over, ' + moveColor + ' is in checkmate.'
      }
      // draw?
      else if (game.in_draw()) {
        status = 'Game over, drawn position'
      }
      // game still on
      else {
        status = moveColor + ' to move'
        // check?
        if (game.in_check()) {
          status += ', ' + moveColor + ' is in check'
        }
      }
      updateOthers()
    }

    function movetofen($this_fen, $move_number) {
      resetBoard()
      board.position($this_fen)

      for (let i = 0; i < $move_number; i++) {
        tmp = future[i].split("-")
        game.move({
            from: tmp[0],
            to: tmp[1],
            promotion: 'q' // NOTE: always promote to a queen for example simplicity
          })
        tmp_future.push(tmp[0] + "-" + tmp[1])
        updateStatus()
      }
      future = tmp_future
      tmp_future = []
      console.log(future)
      console.log(tmp_future)
      // updateStatus()
    }

    function updateOthers() {
      if($("#movefen tr:last td:last").text() != game.fen()) {
        $status.html(status)
        $fen.html(game.fen())
        $pgn.html(game.pgn())
        $("#movefen").append("<tr class='movecontainer'><td class='movecounter'>" + move_counter + "</td><td><a href='#!' class='movetofen'>" + game.fen() + "</a></td><td class='movepgn'>" + game.pgn() + "</td></tr>")
        move_counter += 1
      }
    }

    function onDrop(source, target, piece, newPos, oldPos, orientation) {
      // console.log('Source: ' + source)
      // console.log('Target: ' + target)
      future.push(source + "-" + target)
      // console.log('Piece: ' + piece)
      // console.log('New position: ' + Chessboard.objToFen(newPos))
      // console.log('Old position: ' + Chessboard.objToFen(oldPos))
      // console.log('Orientation: ' + orientation)
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log(game.move({ from: source, to: target }))

      // see if the move is legal
      let move = game.move({
        from: source,
        to: target,
        promotion: 'q' // NOTE: always promote to a queen for example simplicity
      })

      // illegal move
      // if (move === null) return 'snapback'
      updateStatus()
    }

    function resetBoard() {
      move_counter = 0;
      board.position("rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1")
      game.pgn("rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1")
      game.fen("rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1")
      game.load("rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1")
      $pgn.html(game.pgn())
      $fen.html(game.fen())
      $("#movefen").html("")
      updateStatus()
    }
    // Functions end

    // Events Start

    $('#undoBtn').on('click', function() {
      board.move('e2-e5')
    });

    $('#flipBtn').on('click', function() {
      board.orientation('flip')
    });

    $('#resetBtn').on('click', function() {
      resetBoard()
    });
    // $("#boardFEN").html("FEN: " + board.fen());
    // Events End
  })
}

// NOT USED
// function undo() {
//   let moves = game.history();
//   let tmp = new Chess();
//   let previous = moves.length-future.length-move_counter;
//   move_counter -= 1;
//   for(let i=0;i<previous;i++) {
//     tmp.move(moves[i]);
//   }
//   let previous_fen = tmp.fen();
//   tmp.move(moves[previous]);
//   future.push(moves.length-future.length-move_counter);
//   future.push(previous_fen);
//   return previous_fen;
// }
//
// function redo() {
//   next_fen = future.pop();
//   return next_fen;
// }

// $('#undoBtn').on('click', function() {
//   board.move('e2-e5')
// });
//
// $('#redoBtn').on('click', function() {
//   fen = redo();
//   board.position(fen);
//   updateStatus()
//   console.log(fen);
// });