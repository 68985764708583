document.addEventListener('DOMContentLoaded', () => {
  const toggleButtons = document.querySelectorAll('.upload-toggle-description');

  toggleButtons.forEach((button) => {
    button.addEventListener('click', () => {
      console.log('test')
      const descriptionText = button.closest('tr').querySelector('.upload-description-text');
      if (descriptionText.style.display === 'none') {
        descriptionText.style.display = 'block';
      } else {
        descriptionText.style.display = 'none';
      }
    });
  });
});